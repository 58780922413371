(function () {
    var anchors = ['home', 'about', 'works', 'contacts'],
        animatedLogos = document.querySelectorAll('.js-animate-logo'),
        menuLinks = document.querySelectorAll('.menu a'),
        portfolioLinks = document.querySelectorAll('.js-toggle-portfolio'),
        toggleContactsLink = document.querySelectorAll('.js-toggle-contacts'),
        portfolioAmount = document.querySelectorAll('.js-toggle-portfolio[data-index]').length,
        paginationLinks = document.querySelectorAll('.js-pagination'),
        slidesNavigationLinks = document.querySelectorAll('.js-slide-navigation-link'),
        currentProjectIndex = null,
        mobileMaxWidth = 950,
        animationDelay = 200,
        animatedEls = document.querySelectorAll('.js-animate'),
        slideContactsLinks = document.querySelectorAll('.js-slide-contacts'),
        screenMode = detectScreenMode(),
        portfolioItems = document.querySelectorAll('.js-portfolio-item'),
        portfolioBlock = document.querySelector('.js-portfolio'),
        contactsBlock = document.querySelector('.js-contacts'),
        projectBackgrounds = document.querySelectorAll('.js-project-background'),
        numbers = document.querySelectorAll('.number');

    function detectScreenMode() {
        return window.innerWidth <= mobileMaxWidth ? 'mobile' : 'desktop';
    }

    function initializeSliders() {
      var elsNumber = $('.js-project-slider:visible:not(.slick-initialized) .js-slick-element').length;
      var slidesToShow = elsNumber > 3 ? 3 : (elsNumber === 3 ? 2 : 1);
        $('.js-project-slider:visible:not(.slick-initialized)').slick({
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: slidesToShow,
          dots: elsNumber > 1,
          arrows: false,
          infinite: true,
          adaptiveHeight: true
        });
    }

    function initializeFullpage() {
        $('#fullpage').fullpage({
            scrollHorizontallyKey: 'bmF0aWtpLmNvbV9NR1VjMk55YjJ4c1NHOXlhWHB2Ym5SaGJHeDVTcGY=',
            scrollHorizontally: true,
            controlArrows: false,
            anchors: ['home', 'about', 'works', 'contacts'],
            // menu: '#menu',
            slidesNavigation: true,
            scrollOverflow: true,
            css3: true,
            onLeave: function (index, nextIndex, direction) {
                [].forEach.call(menuLinks, function (link) {
                    link.classList.remove('active');
                });
                [].forEach.call(document.querySelectorAll('.menu a[href="#' + anchors[nextIndex - 1] + '"'), function (link) {
                    link.classList.add('active');
                });
            },
            afterLoad: function (anchorLink, index) {
                clearAnimationClasses();
                animateBlocks('.section[data-index="' + index + '"]');
            },
            afterSlideLoad: function(anchorLink, index, slideAnchor, slideIndex){
              switch(slideAnchor) {
                case "home":
                case "services":
                case "mobile":
                  [].forEach.call(animatedLogos, function (logo) {
                    logo.classList.add('mount');
                  });
                  break;
                case "numbers":
                  [].forEach.call(numbers, function (number) {
                    if (!number.classList.contains('animated')){
                      var numUp = new CountUp(number, 0, +number.dataset.number);
                      if (!numUp.error) {
                        numUp.start();
                        number.classList.add('animated');
                      }
                      else {
                        console.error(numUp.error);
                      }
                    }
                  });
                  break;
              }
              if (['home', 'services', 'mobile'].indexOf(slideAnchor) > -1){
                document.body.classList.add('slide-light');
                document.body.classList.remove('slide-dark');
              } else {
                document.body.classList.add('slide-dark');
                document.body.classList.remove('slide-light');
              }
            },
            onSlideLeave: function(anchorLink, index, slideIndex, direction, nextSlideIndex){
              [].forEach.call(animatedLogos, function (logo) {
                logo.classList.remove('mount');
              });
              if ([0, 2, 4].indexOf(nextSlideIndex) > -1){
                document.body.classList.add('slide-light');
                document.body.classList.remove('slide-dark');
              } else {
                document.body.classList.add('slide-dark');
                document.body.classList.remove('slide-light');
              }
              // switch(slideIndex) {
              //   case 0:
              //     break;
              // }
            }
        });
    }

    function destroyFullpage() {
        $.fn.fullpage.destroy('all');
    }

    function debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this, args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }


    function onResize() {
        if (screenMode === detectScreenMode()) return;
        screenMode = detectScreenMode();
        detectScreenMode() === 'mobile' ? destroyFullpage() : initializeFullpage();
    }


    function togglePortfolio(e) {
        if (portfolioBlock.classList.contains('mount')) {
            portfolioBlock.classList.remove('visible');
            setTimeout(function () {
                portfolioBlock.classList.remove('mount');
            }, 800);

        } else {
          setActivePortfolio(e ? +e.target.dataset.index : 1);
            portfolioBlock.classList.add('mount');
            setTimeout(function () {
                portfolioBlock.classList.add('visible');
                initializeSliders();
            }, 800);
        }
    }

  function toggleContacts(e) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
    if (contactsBlock.classList.contains('mount')) {
      contactsBlock.classList.remove('visible');
      setTimeout(function () {
        contactsBlock.classList.remove('mount');
      }, 800);

    } else {
      contactsBlock.classList.add('mount');
      setTimeout(function () {
        contactsBlock.classList.add('visible');
      }, 800);
    }
  }

     function changeSlide(e) {
      e.stopPropagation();
      e.stopImmediatePropagation();
       var direction = +(e.target.dataset.direction ? e.target.dataset.direction : e.target.parentNode.dataset.direction);
       direction ? $.fn.fullpage.moveSlideRight() : $.fn.fullpage.moveSlideLeft();
     }

    function setActivePortfolio(index) {
      if (index > portfolioAmount || index < 1) return;
        [].forEach.call(portfolioItems, function (item) {
            item.classList.remove('active');
        });
        [].forEach.call(projectBackgrounds, function (item) {
            item.classList.remove('active');
        });
        [].forEach.call(paginationLinks, function (link) {
            link.classList.remove('active');
            link.classList.remove('disabled');
            if (
                (index === 1 && +link.dataset.index === 1) ||
                (index === 1 && link.dataset.index === 'prev') ||
                (index === portfolioAmount && +link.dataset.index === portfolioAmount) ||
                (index === portfolioAmount && link.dataset.index === 'next')
            ) {
                link.classList.add('disabled');
            }
        });
        var backgroundImg = document.querySelector('.js-project-background[data-index="' + index + '"]');
        backgroundImg && backgroundImg.classList.add('active');
        document.querySelector('.js-portfolio-item[data-index="' + index + '"]').classList.add('active');
        currentProjectIndex = index;
    }

    function animateBlocks(containerSelector) {
        var els = document.querySelectorAll(containerSelector + ' .js-animate');

        [].forEach.call(els, function (el) {
            setTimeout(function () {
                el.classList.add('mount');
            }, el.dataset.order * animationDelay)
        });

    }

    function clearAnimationClasses() {
        [].forEach.call(animatedEls, function (el) {
            el.classList.remove('mount');
        });
    }

    function scrollTo(element, to, duration) {
        var start = element.scrollTop,
            change = to - start,
            increment = 20;

        var animateScroll = function (elapsedTime) {
            elapsedTime += increment;
            element.scrollTop = easeInOut(elapsedTime, start, change, duration);
            if (elapsedTime < duration) {
                setTimeout(function () {
                    animateScroll(elapsedTime);
                }, increment);
            }
        };
        animateScroll(0);
    }

    function easeInOut(currentTime, start, change, duration) {
        currentTime /= duration / 2;
        if (currentTime < 1) {
            return change / 2 * currentTime * currentTime + start;
        }
        currentTime -= 1;
        return -change / 2 * (currentTime * (currentTime - 2) - 1) + start;
    }

    (function init() {
        if (screenMode === "desktop") {
            initializeFullpage()
        }

        window.addEventListener('resize', debounce(onResize, 50, false));

        // initializeSliders();

        [].forEach.call(portfolioLinks, function (link) {
            link.addEventListener('click', togglePortfolio);
        });
        [].forEach.call(toggleContactsLink, function (link) {
          link.addEventListener('click', toggleContacts);
        });
        [].forEach.call(paginationLinks, function (link) {
            link.addEventListener('click', function (e) {
                var i = e.target.dataset.index;
                setActivePortfolio(isNaN(+i) ? (i === 'prev' ? (currentProjectIndex - 1) : (currentProjectIndex + 1)) : +i)
            });
        });

         [].forEach.call(slidesNavigationLinks, function (link) {
           link.addEventListener('click', changeSlide);
         });

        [].forEach.call(slideContactsLinks, function (link) {
            link.addEventListener('click', function (e) {
                if (detectScreenMode() === 'desktop') return;
                e.preventDefault();
                scrollTo(document.body, document.querySelector('.slide-contacts').offsetTop, 500);
            });
        });
    })();
})();